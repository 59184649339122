<template>
  <div class="privileged-onboarding-container">

    <div class="common-header">
      <img src="../../assets/image/bKash-logo.svg" class="bkash-logo"><br>
    </div>

    <div class="view--initial" v-if="currentViewName===this.viewNames.INITIAL">
      <div class="common-text pa-2"> Please wait, loading...</div>

      <v-btn color="primary" outlined @click="reloadPage" style="margin-top: 8px">
        Retry
      </v-btn>
    </div>

    <div class="view--initial" v-if="currentViewName===this.viewNames.ERROR_DEBUG">
      <div class="debug-text pa-2" contenteditable="true"> Error details for debugging:
        {{ prepareErrorForDebugging(lastError) }}
      </div>

      <v-btn color="primary" outlined @click="reloadPage" style="margin-top: 8px">
        Retry
      </v-btn>
    </div>

    <div class="view--initial" v-if="currentViewName===this.viewNames.ERROR">
      <div class="common-text pa-6">

        <div style="margin-bottom: 20px">
          <span class="pink-text">An error occurred!</span>
        </div>

        <div style="margin-bottom: 20px">
          Your request to join bkash Business Dashboard could not be processed.
        </div>

        <div style="margin-bottom: 20px">
          For more information: <br/>
          <a :href="portalBaseUrl"><span class="pink-text">{{ stripProtocol(portalBaseUrl) }}</span></a>
        </div>

        <div class="slightly-bold-text">
          Thank you.<br/>
          bKash Ltd.
        </div>

      </div>
    </div>

    <div class="view--initial" v-if="currentViewName===this.viewNames.COMPLETED">
      <div class="common-text pa-6">

        <div style="margin-bottom: 20px">
          <span class="pink-text">Congratulations!</span>
          Your request for online payment collection using Business Dashboard has been completed.
        </div>

        <div style="margin-bottom: 20px">
          You’ll receive a link to set your password on your merchant wallet number.
        </div>

        <div style="margin-bottom: 20px">
          Explore more: <br/>
          <a :href="portalBaseUrl"><span class="pink-text">{{ stripProtocol(portalBaseUrl) }}</span></a>
        </div>

        <div class="slightly-bold-text">
          Thank you.<br/>
          bKash Ltd.
        </div>

      </div>
    </div>

    <div class="view--initial" v-if="currentViewName===this.viewNames.ALREADY_COMPLETED">
      <div class="common-text pa-6">

        <div style="margin-bottom: 30px">
          <span class="pink-text">Dear Merchant!</span>
          You have already signed up for bKash Business Dashboard.
        </div>

        <div style="margin-bottom: 30px">
          To know more about current offers please log in to your account: <br/>
          <a :href="portalSignInUrl"><span class="pink-text">{{ stripProtocol(portalSignInUrl) }}</span></a>
        </div>

        <div class="slightly-bold-text">
          Thank you.<br/>
          bKash Ltd.
        </div>

      </div>
    </div>

    <div class="view--email" v-if="currentViewName===this.viewNames.EMAIL_INPUT">
      <div class="common-text pa-6">

        <div style="text-align: center; margin-top: -30px; margin-bottom: 20px">
          <div style="font-size:32px;font-weight:500;">Welcome to</div>
          <div style="font-size:28px;font-weight:500; color: #95989a;">bKash Business Dashboard</div>
        </div>

        <v-form v-model="isValid" ref="form">

          <div class="input-leader">Enter email address</div>
          <v-text-field
              v-model="userEnteredEmail"
              placeholder="name@company.com"
              outlined
              type="text"
              oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="100"
              dense
              class="inputNumber email-input"
              :rules="emailRules"
              required
              style="width: 401px;"
              @paste.prevent
              autocomplete="off"
          ></v-text-field>

          <div class="input-leader">Re-enter email address</div>
          <v-text-field
              v-model="userEnteredEmailRepeated"
              placeholder="name@company.com"
              outlined
              type="text"
              oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="100"
              dense
              class="inputNumber email-input"
              :rules="emailRulesRepeated"
              required
              style="width: 401px;"
              @paste.prevent
              autocomplete="off"
          ></v-text-field>

        </v-form>

        <v-btn class="email-submit-button" color="primary"
               @click="submitPrivilegedOnboardingRequestWithManuallyEnteredEmail" :disabled="!isValid">
          Proceed
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "PrivilegedOnboarding",

  components: {},

  data() {
    return {
      portalBaseUrl: process.env.VUE_APP_OWN_BASE_URL,
      portalSignInUrl: process.env.VUE_APP_OWN_BASE_URL + '/sign-in',

      wasPreviouslyAttemptedFromDifferentOrigin: false,

      viewNames: {
        INITIAL: "INITIAL",
        COMPLETED: "COMPLETED",
        EMAIL_INPUT: "EMAIL_INPUT",
        ALREADY_COMPLETED: "ALREADY_COMPLETED",
        ERROR: "ERROR",
        ERROR_DEBUG: "ERROR_DEBUG"
      },
      currentViewName: "INITIAL",

      userEnteredEmail: "",
      userEnteredEmailRepeated: "",

      lastError: null,

      inputRules: [
        v => !!v || 'Input is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      emailRulesRepeated: [
        v => !!v || 'Repeating e-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        v => v === this.userEnteredEmail || 'This must match the email you inserted above',
      ],
      isValid: false
    }
  },
  async mounted() {
    // this.setCurrentViewName(this.viewNames.COMPLETED);

    let data = await this.verifyQueryParameters();
    if (!data) {
      this.setCurrentViewName(this.viewNames.ERROR);
    } else {
      this.wasPreviouslyAttemptedFromDifferentOrigin = data.wasPreviouslyAttemptedFromDifferentOrigin;
      if (data.doesUserNeedToProvideEmail) {
        this.setCurrentViewName(this.viewNames.EMAIL_INPUT);
      } else {
        await this.submitPrivilegedOnboardingRequest();
      }
    }
  },
  methods: {
    async submitPrivilegedOnboardingRequestWithManuallyEnteredEmail() {
      await this.submitPrivilegedOnboardingRequest();
    },

    async submitPrivilegedOnboardingRequest() {
      let requestData = this.makeRequestData();

      this.$feedback.showLoading();
      try {
        let {data} = await this.$cppClient.post('auto-onboard/privileged-onboarding/process-request', requestData);
        console.log("Process response:", data);
        this.$feedback.hideLoading();
        this.setCurrentViewName(this.viewNames.COMPLETED);
      } catch ({response}) {
        if (response?.data?.internalCode === "MERCHANT_ONBOARDING_COMPLETED") {
          this.$feedback.hideLoading();
          this.setCurrentViewName(this.viewNames.ALREADY_COMPLETED);
        } else {
          this.lastError = response;
          await this.$feedback.showFailed(response);
          this.setCurrentViewName(this.viewNames.ERROR);
        }
      }
    },

    async verifyQueryParameters() {
      let requestData = this.makeRequestData();

      this.$feedback.showLoading();
      try {
        let {data} = await this.$cppClient.post('auto-onboard/privileged-onboarding/verify-request', requestData);
        console.log("Verification response:", data);
        this.$feedback.hideLoading();
        return data;
      } catch ({response}) {
        this.lastError = response;
        await this.$feedback.showFailed(response);
        return null;
      }
    },

    reloadPage() {
      window.location.reload();
    },

    prepareErrorForDebugging() {
      let output = {
        query: this.$route.query,
        response: this.lastError
      }
      return JSON.stringify(output, null, 2);
    },

    makeRequestData() {
      let query = this.$route.query || {caller: '', data: ''};

      // because request signature is slightly different
      let requestData = {
        caller: query.caller,
        encryptedData: query.data,
        manuallyEnteredEmail: this.isValid && this.userEnteredEmail ? this.userEnteredEmail : null
      }

      return requestData;
    },

    setCurrentViewName(viewName) {
      this.currentViewName = viewName;
    },

    stripProtocol(url) {
      return (url || '').replace('https://', '').replace('http://', '');
    }
  }
}
</script>

<style lang="scss" scoped>

$LaptopW: 1440px;
$MedW: 1024px;
$tabLS: 900px;
$mobileW: 750px;

.privileged-onboarding-container {
  height: 100vh;
  background-color: #FFFFFF;

  @media (max-width: $MedW) {
    width: 100%;
  }
  @media (max-width: $mobileW) {
  }

  .view--initial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
  }

  .view--email {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .common-header {
    .bkash-logo {
      height: 70px;
    }

    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .debug-text {
    font-size: 10px;
    font-family: monospace;
    max-width: 90vw;
  }

  .common-text {
    color: #707070;
    font-size: 20px;
  }

  .pink-text {
    font-weight: 500;
    color: #e2136e;
  }

  .slightly-bold-text {
    font-weight: 500;
    color: #707070;
  }

  .input-leader {
    font-size: 18px;
    margin-bottom: 4px;
    margin-left: 2px;
  }

  .email-submit-button {
    margin-top: 20px;
    width: 100%;
    font-size: 20px;
  }

}

</style>
